define('ember-useragent/services/user-agent', ['exports', 'ua-parser-js'], function (exports, _uaParserJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    _parser: Ember.computed(function () {
      return new _uaParserJs.default();
    }),

    parser: Ember.computed('extensions', {
      get: function get() {
        Ember.deprecate('Usage of the private property `parser` is deprecated. If you need to fiddle around with this private property, please open an issue and we can discuss.', false, { id: 'ember-useragent.service.parser', until: '1.0.0' });

        return Ember.get(this, '_parser');
      }
    }).readOnly(),

    UAParser: Ember.computed(function () {
      Ember.deprecate('Usage of the property `UAParser` is deprecated. To get the UAParser class, import it as `import UAParser from \'ua-parser-js\';`. To get the instance of that class used by this service, as this property previously incorrectly returned, get the `parser` property.', false, { id: 'ember-useragent.service.UAParser', until: '1.0.0' });

      return Ember.get(this, '_parser');
    }),

    userAgent: Ember.computed({
      get: function get() {
        var parser = Ember.get(this, '_parser');
        return parser.getUA();
      },
      set: function set(key, value) {
        var parser = Ember.get(this, '_parser');
        parser.setUA(value);
        Ember.set(this, '_parser', parser);

        return value;
      }
    }),

    fastboot: Ember.computed(function () {
      Ember.deprecate('Usage of the private property `fastboot` is deprecated. Inject the `fastboot` service yourself instead.', false, { id: 'ember-useragent.service.fastboot', until: '1.0.0' });

      return Ember.getOwner(this).lookup('service:fastboot');
    }),

    isFastBoot: Ember.computed(function () {
      Ember.deprecate('Usage of the private property `isFastBoot` is deprecated. Inject the `fastboot` service yourself and get `fastboot.isFastBoot` instead.', false, { id: 'ember-useragent.service.isFastBoot', until: '1.0.0' });

      var fastboot = Ember.getOwner(this).lookup('service:fastboot');
      return Boolean(fastboot && Ember.get(fastboot, 'isFastBoot'));
    }),

    browser: Ember.computed('_parser', function () {
      var browser = Ember.get(this, '_parser').getBrowser();

      return {
        info: browser,
        isChrome: browser.name === 'Chrome',
        isChromeHeadless: browser.name === 'Chrome Headless',
        isEdge: browser.name === 'Edge',
        isFirefox: browser.name === 'Firefox',
        isIE: browser.name === 'IE' || browser.name === 'IE Mobile',
        isSafari: browser.name === 'Safari' || browser.name === 'Mobile Safari'
      };
    }),

    cpu: Ember.computed('_parser', function () {
      return Ember.get(this, '_parser').getCPU();
    }),

    device: Ember.computed('_parser', function () {
      var device = Ember.get(this, '_parser').getDevice();

      return {
        info: device,
        isConsole: device.type === 'console',
        isDesktop: !device.type,
        isMobile: device.type === 'mobile',
        isTablet: device.type === 'tablet'
      };
    }),

    engine: Ember.computed('_parser', function () {
      var engine = Ember.get(this, '_parser').getEngine();

      return {
        info: engine,
        isWebkit: engine.name === 'WebKit'
      };
    }),

    os: Ember.computed('_parser', function () {
      var os = Ember.get(this, '_parser').getOS();

      return {
        info: os,
        isAndroid: os.name === 'Android',
        isIOS: os.name === 'iOS',
        isLinux: ['CentOS', 'Fedora', 'Linpus', 'Linux', 'MeeGo', 'PCLinuxOS', 'RedHat', 'SUSE', 'Ubuntu', 'VectorLinux'].indexOf(os.name) > -1,
        isMacOS: os.name === 'Mac OS',
        isWindows: ['Windows', 'Windows Phone', 'Windows Mobile'].indexOf(os.name) > -1
      };
    }),

    setupService: function setupService() {
      Ember.deprecate('Usage of the private method `setupService` is deprecated. To force an update, set the `userAgent` property.', false, { id: 'ember-useragent.service.setupService', until: '1.0.0' });

      this.notifyPropertyChange('userAgent');
    }
  });
});