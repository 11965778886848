define('ember-paper/components/paper-dialog', ['exports', 'ember-paper/templates/components/paper-dialog', 'ember-invoke-action'], function (exports, _paperDialog, _emberInvokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _paperDialog.default,
    tagName: '',

    escapeToClose: true,
    focusOnOpen: true,
    opaque: true,

    // Calculate a default that is always valid for the parent of the backdrop.
    wormholeSelector: '#paper-wormhole',
    defaultedParent: Ember.computed.or('parent', 'wormholeSelector'),

    // Calculate a default that is always valid where the opening transition should originate.
    defaultedOpenFrom: Ember.computed.or('openFrom', 'origin', 'parent'),

    // Calculate a default that is always valid where the closing transition should terminate.
    defaultedCloseTo: Ember.computed.or('closeTo', 'origin', 'parent'),

    // Calculate the id of the wormhole destination, setting it if need be. The
    // id is that of the 'parent', if provided, or 'paper-wormhole' if not.
    destinationId: Ember.computed('defaultedParent', function () {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');

      if (config.environment === 'test' && !this.get('parent')) {
        return '#ember-testing';
      }
      var parent = this.get('defaultedParent');
      var parentEle = typeof parent === 'string' ? document.querySelector(parent) : parent;
      // If the parentEle isn't found, assume that it is an id, but that the DOM doesn't
      // exist yet. This only happens during integration tests or if entire application
      // route is a dialog.
      if (typeof parent === 'string' && parent.charAt(0) === '#') {
        return '#' + parent.substring(1);
      } else {
        var id = parentEle.getAttribute('id');
        if (!id) {
          id = this.elementId + '-parent';
          parentEle.setAttribute('id', id);
        }
        return '#' + id;
      }
    }),

    // Find the element referenced by destinationId
    destinationEl: Ember.computed('destinationId', function () {
      return document.querySelector(this.get('destinationId'));
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get('escapeToClose')) {

        this._destinationEle = document.querySelector(this.get('destinationId'));
        this._onKeyDown = function (e) {
          if (e.keyCode === 27 && _this.get('onClose')) {
            (0, _emberInvokeAction.invokeAction)(_this, 'onClose');
          }
        };
        this._destinationEle.addEventListener('keydown', this._onKeyDown);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.get('escapeToClose') && this._destinationEle) {
        this._destinationEle.removeEventListener('keydown', this._onKeyDown);
        this._onKeyDown = null;
      }
    },


    actions: {
      outsideClicked: function outsideClicked() {
        if (this.get('clickOutsideToClose') && this.get('onClose')) {
          (0, _emberInvokeAction.invokeAction)(this, 'onClose');
        }
      }
    }
  });
});