define('ember-paper/services/paper-theme', ['exports', 'ember-paper/utils/palettes', 'ember-paper/utils/contrasts', 'tinycolor2'], function (exports, _palettes, _contrasts, _tinycolor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this._themes = {};
    },
    installTheme: function installTheme() {
      var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';
      var theme = arguments[1];
      var selector = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ':root';

      if (this._themes[name]) {
        this.uninstallTheme(name);
      }

      // let's not make the background palette mandatory as it will be the `grey`
      // palette most of the time
      theme = Ember.assign({ background: _palettes.default.grey }, theme);

      var vars = this.generateCssVariables(theme);

      var css = '\n      ' + selector + ' {\n        ' + vars + '\n      }\n    ';

      var styleElement = document.createElement('style');
      styleElement.setAttribute('id', 'paper-theme-' + name);
      styleElement.type = 'text/css';
      document.head.appendChild(styleElement);
      styleElement.sheet.insertRule(css, 0);

      this._themes[name] = styleElement;
    },
    uninstallTheme: function uninstallTheme(name) {
      (false && !(!!name) && Ember.assert('A name is required. `' + name + '` was passed to `uninstallTheme()`', !!name));


      var styleElement = this._themes[name];

      (false && !(!!styleElement) && Ember.assert('No installed theme with name `' + name + '` was found.', !!styleElement));


      document.head.removeChild(styleElement);
      delete this._themes[name];
    },


    /**
     * This is essentially a port of the paper-contrast-color sass function.
     *
     * @param {Palette} palette
     * @param {string} hue
     */
    getContrastColor: function getContrastColor(_ref, hue) {
      var contrastDarkColors = _ref.contrastDarkColors,
          contrastLightColors = _ref.contrastLightColors,
          contrastStrongLightColors = _ref.contrastStrongLightColors,
          contrastDefaultColor = _ref.contrastDefaultColor;

      if (contrastDarkColors && contrastDarkColors.includes(hue)) {
        return _contrasts.default['dark-contrast-color'];
      } else if (contrastLightColors && contrastLightColors.includes(hue)) {
        return _contrasts.default['light-contrast-color'];
      } else if (contrastStrongLightColors && contrastStrongLightColors.includes(hue)) {
        return _contrasts.default['strong-light-contrast-color'];
      } else {
        return contrastDefaultColor;
      }
    },
    generateCssVariables: function generateCssVariables(theme) {
      var _this = this;

      return ['primary', 'accent', 'warn', 'background'].reduce(function (vars, intention) {
        var palette = theme[intention];

        (false && !(!!palette) && Ember.assert('A theme must have a `' + intention + '` key containing a palette.', !!palette));


        var hues = Object.keys(palette).filter(function (hue) {
          return hue.indexOf('contrast') === -1;
        });

        return vars += hues.map(function (hue) {
          var color = (0, _tinycolor.default)(palette[hue]).toRgb();
          var contrast = (0, _tinycolor.default)(_this.getContrastColor(palette, hue)).toRgb();

          return '\n          --' + intention + '-' + hue + ': ' + color.r + ', ' + color.g + ', ' + color.b + ';\n          --' + intention + '-' + hue + '-contrast: ' + contrast.r + ', ' + contrast.g + ', ' + contrast.b + ';\n        ';
        }).join('');
      }, '');
    }
  });
});